import ApiService from './ApiService';

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: '/auth/login',
    method: 'post',
    data,
  });
}

export async function apiGetMe(params) {
  return ApiService.fetchData({
    url: '/auth/me',
    method: 'get',
    params,
  });
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: '/auth/register',
    method: 'post',
    data,
  });
}

export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: '/auth/logout',
    method: 'post',
    data,
  });
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: '/auth/forgot-password',
    method: 'post',
    data,
  });
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: '/reset-password',
    method: 'post',
    data,
  });
}

export async function apiChangePassword(data) {
  return ApiService.fetchData({
    url: '/auth/change-password',
    method: 'post',
    data,
  });
}
