import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  roles: [],
};

export const roleSlice = createSlice({
  name: 'base/roles',
  initialState,
  reducers: {
    setRoles: (_, action) => action.payload,
  },
});

export const { setRoles } = roleSlice.actions;

export default roleSlice.reducer;
