import { combineReducers } from '@reduxjs/toolkit';
import common from './commonSlice';
import province from './provinceSlice';
import role from './roleSlice';

const reducer = combineReducers({
  common,
  province,
  role,
});

export default reducer;
