import { useSelector, useDispatch } from 'react-redux';
import { setUser, initialState } from 'store/auth/userSlice';
import { apiSignIn, apiSignOut, apiSignUp, apiGetMe } from 'services/AuthService';
import { onSignIn, onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice';
import appConfig from 'configs/app.config';
import { REDIRECT_URL_KEY } from 'constants/app.constant';
import { useNavigate } from 'react-router-dom';
import useQuery from './useQuery';
import useBase from './useBase';

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { access_token, refresh_token, signedIn } = useSelector((state) => state.auth.session);
  const { user } = useSelector((state) => state.auth.user);

  const { getProvince, getRole } = useBase();

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values);
      if (resp.data) {
        window.localStorage.removeItem('admin');
        dispatch(
          onSignInSuccess({
            access_token: resp.data?.data?.access_token || '',
            access_token_expires_at: resp.data?.data?.access_token_expires_at || '',
            refresh_token: resp.data?.data?.refresh_token || '',
            refresh_token_expires_at: resp.data?.data?.refresh_token_expires_at || '',
            session_id: resp.data?.data?.session_id || '',
          }),
        );

        return async () => {
          const res = await getMe();

          if (res?.status === 'success') {
            await getProvince();
            await getRole();
            dispatch(onSignIn(true));
          } else {
            throw res.message;
          }

          return {
            status: 'success',
            message: '',
          };
        };
      }
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const getMe = async () => {
    try {
      const resp = await apiGetMe({
        relations: 'userCompany,userRole.role.rolePermissions.permission',
      });
      if (resp.data) {
        dispatch(
          setUser({
            authority: resp?.data?.data?.userRole?.role?.rolePermissions?.map(
              (rolePermissions) => rolePermissions?.permission?.slug,
            ),
            user: resp.data.data,
          }),
        );
      }
      return {
        status: 'success',
        message: true,
      };
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: '',
                userName: 'Anonymous',
                authority: ['USER'],
                email: '',
              },
            ),
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: 'success',
          message: '',
        };
      }
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    dispatch(onSignIn(false));

    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    await apiSignOut({ access_token: access_token });
    handleSignOut();
  };

  return {
    authenticated: access_token && signedIn,
    signIn,
    signUp,
    signOut,
    getMe,
    user,
  };
}

export default useAuth;
