import { apiGetRoleService } from 'services/RoleService';
import { setRoles } from 'store/base/roleSlice';

const { useDispatch, useSelector } = require('react-redux');
const { apiGetProvince } = require('services/ProvinceService');
const { setProvinces } = require('store/base/provinceSlice');

function useBase() {
  const dispatch = useDispatch();

  const { provinces } = useSelector((state) => state.base.province);
  const { roles } = useSelector((state) => state.base.role);

  const getRole = async () => {
    try {
      const resp = await apiGetRoleService({ type: 'collection', options: ['filter,is_administrator,equal,1'] });
      if (resp.data) {
        dispatch(
          setRoles({
            roles: resp.data.data,
          }),
        );
      }
    } catch (error) {}
  };

  const getProvince = async () => {
    try {
      const resp = await apiGetProvince({ relations: 'regencies', type: 'collection' });
      if (resp.data) {
        dispatch(
          setProvinces({
            provinces: resp.data.data,
          }),
        );
      }
    } catch (error) {}
  };

  return {
    provinces,
    roles,
    getProvince,
    getRole,
  };
}

export default useBase;
