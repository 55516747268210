import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  provinces: [],
};

export const provinceSlice = createSlice({
  name: "base/province",
  initialState,
  reducers: {
    setProvinces: (_, action) => action.payload,
  },
});

export const { setProvinces } = provinceSlice.actions;

export default provinceSlice.reducer;
